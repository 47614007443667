import React from 'react';
import { Helmet } from "react-helmet";

import Spinner from '../components/spinner'

import oferta1 from '../img/oferta1.svg';
import oferta2 from '../img/oferta2.svg';
import oferta3 from '../img/oferta3.svg';
import oferta4 from '../img/oferta4.svg';
import oferta5 from '../img/oferta5.svg';

export default class Oferta extends React.Component {
  constructor() {
    super();
    this.ofertaImg = [oferta1, oferta2, oferta3, oferta4, oferta5]
  }

  render() {
    return (
      (this.props.page)
        ? <article className="Kontakt">
          <Helmet>
            <title>Oferta - Vowie Studio</title>
            <meta property="og:type" content="article" />
            <meta property="og:title" content={'Oferta - Vowie Studio'} />
            {(this.props.page && this.props.page.acf)
              ? <meta name="description" content={this.props.page.acf.description} />
              : null}
            {(this.props.page && this.props.page.acf)
              ? <meta name="og:description" content={this.props.page.acf.description} />
              : null}
          </Helmet>
          <div className="Title Title--no-title" />
          <section className="Kontakt__top">
            <div className="Kontakt__content" dangerouslySetInnerHTML={{ __html: this.props.page.content.rendered }} />
          </section>
          <section className="Kontakt__bottom">
            <ul className="Kontakt__oferta-tiles">
              {Object.keys(this.props.oferta).map((v, i) =>
                <li className="Kontakt__tile" key={i}>
                  <div className="Kontakt__img-wrap">
                    <img src={this.ofertaImg[i]} alt={this.props.oferta[v]} nopin="nopin" className="Kontakt__tile-img" />
                  </div>
                  <div className="Kontakt__tile-text" dangerouslySetInnerHTML={{ __html: this.props.oferta[v] }} />

                </li>)}
            </ul>
          </section>
          <section className="Kontakt__lista-projektow">
            <div className="Kontakt__projekty" dangerouslySetInnerHTML={{ __html: this.props.page.acf.projekty }} />
          </section>
        </article>
        : <Spinner />
    );
  }

}


