import React from 'react';

import '../scss/ArrowScroller.css';
import arrow from '../img/arr-up.png'

export default class ShowDetails extends React.Component {
  constructor() {
    super();
    // this.state = {onTop: false};
    // window.addEventListener('scroll', () => {
    //     if ($(this).scrollTop() > 900) {
    //         arrow.fadeIn();
    //     } else {
    //         arrow.fadeOut();
    //     }
    // });
    this.scrollToTop = this.scrollToTop.bind(this);
  }

  scrollToTop(scrollDuration) {
    const scrollStep = -window.scrollY / (scrollDuration / 15),
      scrollInterval = setInterval(function () {
        if (window.scrollY !== 0) {
          window.scrollBy(0, scrollStep);
        }
        else clearInterval(scrollInterval);
      }, 15);
  }

  render() {
    return (
      <div className="ArrowScroller" onClick={() => { this.scrollToTop(500) }}>
        <img src={arrow} alt="Przewiń do góry" nopin="nopin" /><br />menu
            </div>
    )
  }
}
