import React from 'react'
import { NavLink } from 'react-router-dom'

export default class extends React.Component {

    render() {
        return <li className={(this.props.liclass)
        ? this.props.liclass
        :'Menu__item'}>
            <NavLink {...this.props} activeClassName="active"/>
        </li>
    }
};