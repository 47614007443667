import React from 'react'
import { Helmet } from "react-helmet";
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom'

import App from './App';
import LandingPage from '../components/LandingPage';
import { RouteWithProps, url } from '../_shared'

import '../scss/LandingPage.css';

export default class Routes extends React.Component {

  constructor() {
    super();
    this.state = {
      types: null
    }
  }

  componentDidMount() {
    this.getProjectTypes();
    this.fetchProjects();
    this.fetchKontakt();
    this.fetchOferta();
    this.fetchOnas();
  }

  getProjectTypes() {
    fetch(`${url}/wp/v2/projecttypes`)
      .then(response => response.json(), reason => { console.log(reason) })
      .then(json => {
        this.setState({ types: json });
      });
  }


  /**
   * Fetches all projects and filters them afterwards
   */

  fetchProjects() {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        if (httpRequest.status === 200) {
          this.projects = JSON.parse(httpRequest.response);
          const pages = Number(httpRequest.getResponseHeader('X-WP-TotalPages'));
          if (pages > 1) {
            for (let i = 2; i <= pages; i++) {
              this.doprojXMLrequest(`${url}/wp/v2/projekty&_embed&per_page=100&page=${i}`, pages, i)
            }
          } else {
            this.setState({
              posts: this.projects
            });
          }
        } else {
          console.log('There was a problem with the request.');
        }
      }
    };
    httpRequest.open(`GET`, `${url}/wp/v2/projekty&_embed&per_page=100`);
    httpRequest.send();
  }

  doprojXMLrequest(url, pages, page) {
    const httpRequest = new XMLHttpRequest();
    httpRequest.onreadystatechange = () => {
      if (httpRequest.readyState === XMLHttpRequest.DONE) {
        if (httpRequest.status === 200) {
          this.projects = this.projects.concat(JSON.parse(httpRequest.response));
          if (pages === page) {
            this.setState({
              posts: this.projects
            });
          }
        } else {
          console.log('There was a problem with the request.');
        }
      }
    };
    httpRequest.open(`GET`, url);
    httpRequest.send();
  }

  fetchKontakt() {
    fetch(`${url}/wp/v2/pages/13`)
      .then(resp => resp.json())
      .then(resp => {
        this.setState({
          kontaktData: resp
        });
        console.log('fetched')
      }, reason => {
        console.log(reason)
      });
  }

  fetchOferta() {
    fetch(`${url}/wp/v2/pages/80`)
      .then(resp => resp.json(), reason => {
        console.log(reason)
      })
      .then(resp => {
        this.setState({
          ofertaData: resp,
          oferta: resp.acf.oferta[0]
        });
      });
  }

  fetchOnas() {
    fetch(`${url}/wp/v2/pages/76&_embed`)
      .then(resp => resp.json(), reason => {
        console.log(reason)
      })
      .then(resp => {
        this.setState({
          onasData: resp
        });
      });
  }


  render() {
    return (
      <div>
        <Helmet>
          <meta property="og:title" content="Vowie Studio" />
          <meta property="og:type" content="website" />
          <meta property="og:image" content="https://vowie.com.pl/vowielogo.png" />
        </Helmet>
        <Router>
          <Switch>
            <Route exact path="/" component={LandingPage} />
            <RouteWithProps path="/" types={this.state.types} fetched={this.state} component={App} />
          </Switch>
        </Router>
      </div>
    );
  }
}