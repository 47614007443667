import React from 'react';
import { CSSTransitionGroup } from 'react-transition-group'

import { Switch } from 'react-router-dom'
import { RouteWithProps } from '../_shared';

import Projekty from './Projekty';
import Onas from './Onas';
import Oferta from './Oferta';
import Kontakt from './Kontakt';


import Menu from '../components/Menu';
import Spinner from '../components/spinner';
import ArrowScroller from '../components/ArrowScroller'

import '../scss/App.css';
import '../scss/Footer.css';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            animationKey: this.processAnimationKey(props.location.pathname)
        }
        // this.handleScroll = this.handleScroll.bind(this);
    }

    componentWillReceiveProps(nextProps) {
        this.setState(() => ({
            animationKey: this.processAnimationKey(nextProps.location.pathname)
        }));
    }
    /**
     * Processes location pathname so it will ignore gallery view url change (no ShowDetails animation)
     * Additionally merges /projekty with /projekty/wszystkie
     * @param {*} string 
     */
    processAnimationKey(string) {
        const result = string.split('/').slice(0, 3).join('/');

        return (result === '/projekty') ? '/projekty/wszystkie' : result;
    }

    // componentDidMount() {
    //     window.addEventListener('scroll', this.handleScroll);
    // }
    //
    // componentWillUnmount() {
    //     window.removeEventListener('scroll', this.handleScroll);
    // }
    //
    // handleScroll() {
    //     // if (window.innerWidth > 768) {
    //     //     this.setState({marginTop: `${window.pageYOffset}px`});
    //     // } else {
    //     //     this.setState({marginTop: `0px`});
    //     // }
    //
    // }

    render() {
        return (
            <div className="App-wrapper">
                <div className="App">
                    <div className="App__menu">
                        {(this.props.types)
                            ? <Menu types={this.props.types} location={this.props.location} />
                            : <Spinner />
                        }
                    </div>
                    <CSSTransitionGroup
                        transitionName="routeTrans"
                        transitionAppear={true}
                        transitionAppearTimeout={1000}
                        transitionEnterTimeout={1000}
                        transitionLeaveTimeout={300}
                        component="main"
                        className="App__content">
                        <Switch location={this.props.location} key={this.state.animationKey}>
                            {(this.props.types)
                                ? <RouteWithProps path="/projekty" key="projekty" types={this.props.types} posts={this.props.fetched.posts}
                                    component={Projekty} />
                                : ''
                            }
                            {(this.props.types)
                                ? <RouteWithProps path="/galeria-projektow.html" key="projekty" types={this.props.types}
                                    posts={this.props.fetched.posts} component={Projekty} />
                                : ''
                            }
                            <RouteWithProps exact path="/oferta" key="oferta" component={Oferta} page={this.props.fetched.ofertaData}
                                oferta={this.props.fetched.oferta} />
                            <RouteWithProps exact path="/o-nas" key="o-nas" component={Onas} page={this.props.fetched.onasData} />
                            <RouteWithProps exact path="/kontakt" key="kontakt" component={Kontakt} page={this.props.fetched.kontaktData} />
                            <RouteWithProps exact path="/kontakt.html" key="kontakt" component={Kontakt} page={this.props.fetched.kontaktData} />
                        </Switch>
                    </CSSTransitionGroup>

                    <div className="App__counterbalance" />
                    <ArrowScroller />
                </div>
                <footer className="Footer">
                    <div className="Footer__footer">
                        &copy; {new Date().getFullYear()} Vowie Studio<br />
                        <small>Developed by <a href="http://adyry.com" target="_blank" rel="noopener noreferrer">adyry</a></small>
                    </div>
                </footer>
            </div>
        )
    }
}

export default App;
