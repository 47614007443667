import React from 'react';
import { Helmet } from "react-helmet";

import Spinner from '../components/spinner';

export default class Onas extends React.Component {
  render() {
    return (
      (this.props.page)
        ? <article className="Kontakt">
          <Helmet>
            <title>O nas - Vowie Studio</title>
            <meta property="og:type" content="article" />
            <meta property="og:title" content={'O nas - Vowie Studio'} />
            {(this.props.page && this.props.page.acf)
              ? <meta name="description" content={this.props.page.acf.description} />
              : null}
            {(this.props.page && this.props.page.acf)
              ? <meta name="og:description" content={this.props.page.acf.description} />
              : null}
          </Helmet>
          <div className="Title Title--no-title" />
          <section className="Kontakt__top Kontakt__top--onas">
            <div className="Kontakt__left">
              <img className="Kontakt__img" nopin="nopin" src={
                this.props.page._embedded["wp:featuredmedia"]["0"].media_details.sizes.medium.source_url
              } alt="Vowie Studio" />
            </div>
            <div className="Kontakt__right Kontakt__right--onas"
              dangerouslySetInnerHTML={{ __html: this.props.page.acf.tekst_nad_zdjeciem }} />
          </section>
          <section className="Kontakt__bottom">
            <span className="Kontakt__bottom-text"
              dangerouslySetInnerHTML={{ __html: this.props.page.content.rendered }} />
          </section>

        </article>
        : <Spinner />
    );
  }

}


