/*global google*/

import React from 'react';
import { Helmet } from "react-helmet";

import '../scss/Kontakt.css';
import '../scss/ContactForm.css';
import '../scss/Title.css';

import pin from '../img/pin.svg'
import facebook from '../img/fb.svg'
import facebookrev from '../img/fb-reverse.svg'
import { loadJS } from '../_shared';


export default class Kontakt extends React.Component {
  constructor(props) {
    super(props);
    this.initMap = this.initMap.bind(this);
    if (!window.google) {
      this.initGoogleMapsScript();
    }
  }

  componentDidMount() {
    if (!this.googleInit) {
      this.initMap();
    }
  }

  initGoogleMapsScript() {
    this.googleInit = true;
    window.initMap = this.initMap;
    loadJS('https://maps.googleapis.com/maps/api/js?key=AIzaSyAfRr_513Sa-BxNqabdyIi8SeaQCGpA0ak&callback=initMap');
  }

  initMap() {
    const map = new window.google.maps.Map(this.mapa, {
      center: { lat: 52.614047, lng: 16.585424 },
      zoom: 16,
      disableDefaultUI: true,
      gestureHandling: 'cooperative',
      styles: [
        {
          "featureType": "all",
          "elementType": "geometry",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "all",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.country",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.province",
          "elementType": "all",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.locality",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#2c2c2c"
            },
            {
              "weight": "2.00"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "elementType": "labels",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "administrative.neighborhood",
          "elementType": "labels.text.fill",
          "stylers": [
            {
              "color": "#696969"
            }
          ]
        },
        {
          "featureType": "landscape",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#ffffff"
            }
          ]
        },
        {
          "featureType": "landscape.man_made",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "poi.park",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#f6f6f6"
            },
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "weight": "0.50"
            }
          ]
        },
        {
          "featureType": "road",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#565656"
            },
            {
              "weight": "0.50"
            }
          ]
        },
        {
          "featureType": "road.highway",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            },
            {
              "weight": "0.01"
            }
          ]
        },
        {
          "featureType": "road.highway.controlled_access",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "weight": "0.50"
            },
            {
              "color": "#8e8e8e"
            }
          ]
        },
        {
          "featureType": "road.highway.controlled_access",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#000000"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            },
            {
              "weight": "0.50"
            }
          ]
        },
        {
          "featureType": "road.arterial",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#b7b7b7"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "road.local",
          "elementType": "labels.text",
          "stylers": [
            {
              "visibility": "on"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#404040"
            }
          ]
        },
        {
          "featureType": "transit.line",
          "elementType": "geometry.stroke",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "transit.station",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "visibility": "off"
            }
          ]
        },
        {
          "featureType": "water",
          "elementType": "geometry.fill",
          "stylers": [
            {
              "color": "#dedede"
            }
          ]
        }
      ]
    });

    const marker = new google.maps.Marker({
      position: { lat: 52.614717, lng: 16.591282 },
      visible: false,
      map,
      opacity: 0,
      icon: { url: pin, scaledSize: new google.maps.Size(34, 57) },
      animation: google.maps.Animation.DROP
    });

    setTimeout(() => { marker.setVisible(true) }, 1000)
    setTimeout(() => { marker.setOpacity(1) }, 1100)

    this.repositionMap(map);
    window.addEventListener("resize", () => { this.repositionMap(map) })
  }

  repositionMap(map) {
    if (window.outerWidth > 768) {
      map.setCenter({ lat: 52.614047, lng: 16.585424 });
    }
    if (window.outerWidth <= 768) {
      map.setCenter({ lat: 52.614717, lng: 16.591282 });
    }
  }

  submitForm(e) {
    e.preventDefault();
    if (this.refs.contactForm.checkValidity()) {
      const httpRequest = new XMLHttpRequest();
      httpRequest.onreadystatechange = () => {
        if (httpRequest.readyState === XMLHttpRequest.DONE) {
          if (httpRequest.status === 200) {
            this.submitButton.innerText = 'Wysłano!'
          } else {
            alert('Wystąpił problem z formularzem kontaktowym');
          }
        }
      };
      httpRequest.open(`POST`, './contact_me.php');
      httpRequest.send(new FormData(this.refs.contactForm));
    }
  }


  render() {
    return (
      <article className="Kontakt">
        <Helmet>
          <title>Kontakt - Vowie Studio</title>
          {(this.props.page && this.props.page.acf)
            ? <meta name="description" content={this.props.page.acf.description} />
            : null}
          <meta property="og:type" content="article" />
          <meta property="og:title" content={'Kontakt - Vowie Studio'} />
          {(this.props.page && this.props.page.acf)
            ? <meta name="og:description" content={this.props.page.acf.description} />
            : null}
        </Helmet>
        <h1 className="Title">
          {(this.props.page)
            ? this.props.page.acf.tytul
            : ''}
        </h1>
        <section className="Kontakt__top">
          {(this.props.page)
            ? <div className="Kontakt__left" dangerouslySetInnerHTML={{ __html: this.props.page.content.rendered }} />
            : <div />}
          <div className="Kontakt__right">
            <form ref="contactForm" name="sentMessage" className="ContactForm">
              <input className="ContactForm__element ContactForm__element--input" id="name" name="name" placeholder="imię i nazwisko / nazwa firmy" type="text" required />
              <input className="ContactForm__element ContactForm__element--input" id="email" name="email" placeholder="email" type="email" required />
              <textarea className="ContactForm__element ContactForm__element--textarea" id="message" name="message" placeholder="wiadomość" rows="7" required />
              <button className="ContactForm__submit" type="submit"
                onClick={(e) => this.submitForm(e)}
                ref={(ref) => { this.submitButton = ref; }}>
                WYŚLIJ
                        </button>
            </form>
          </div>
        </section>
        <section className="Kontakt__map">
          <div ref={(ref) => { this.mapa = ref; }} style={{ height: '350px', width: '100%' }} />
        </section>
        <section className="Kontakt__social">
          <a href="https://www.facebook.com/vowiestudio/" target="_blank" className="Kontakt__facebook" rel="noopener noreferrer">
            <img src={facebook} alt="Vowie Studio Facebook" nopin="nopin" className="Kontakt__fb Kontakt__fb--inactive" />
            <img src={facebookrev} alt="Vowie Studio Facebook" nopin="nopin" className="Kontakt__fb Kontakt__fb--active" />
          </a>
        </section>
      </article>
    );
  }
}

