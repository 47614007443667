import React from 'react'
import {NavLink} from 'react-router-dom'

import logo from '../img/logo-text.svg';
import facebook from '../img/fb.png'
import facebookrev from '../img/fb-reverse.png'
import wind from '../img/wind.png'
// import coronainfo from '../img/coronainfo.png'
// import close from '../img/close.svg'


import Popover from '@material-ui/core/Popover';

import '../scss/LandingPage.css';
import {loadJS} from '../_shared';

export default class LandingPage extends React.Component {
  state = {
    anchorEl: this.butt,
    open: false,
      coronaOpen: true
  };

  handleClick = event => {
    this.setState({
      // anchorEl: event.currentTarget,
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  componentDidMount() {
    window.__MUI_USE_NEXT_TYPOGRAPHY_VARIANTS__ = true;
    loadJS('https://airly.eu/map/airly.js');
  }

  render() {

    return (
      <div className="LandingPage" onClick={() => this.setState({coronaOpen: false})}>
        <div className="LandingPage__lighten">
          <div className="LandingPage__content">
            <div className="LandingPage__right">
              <NavLink to="/projekty">
                <img src={logo} alt="Autorska pracownia Architektoniczna Vowie Studio Plus." nopin="nopin" className="LandingPage__logo" />
              </NavLink>
            </div>
            <div className="LandingPage__left">
              <div className="LandingPage__menu">
                <NavLink to="/projekty" alt="Zobacz nasze realizacje">projekty</NavLink>
                <NavLink to="/oferta" alt="Zobacz ofertę">oferta</NavLink>
                <NavLink to="/o-nas" alt="Dowiedz się więcej o naszej działalności">o nas</NavLink>
                <NavLink to="/kontakt" alt="Skontaktuj się z nami">kontakt</NavLink>
              </div>
              <div className="LandingPage__logo-mobile">
                <NavLink to="/projekty">
                  <img src={logo} className="LandingPage__logo" nopin="nopin" alt="Vowie Studio logo" />
                </NavLink>
              </div>
              <div className="LandingPage__contact">
                <p><a href="mailto:biuro@vowie.com.pl">biuro@vowie.com.pl</a></p>
                <p>tel: <a href="tel:612932144">61 29 32 144</a></p>
                <p><a href="tel:612922821">61 29 22 821</a></p>
                <p>al. Jana Pawła II 20</p>
                <p>64-500 Szamotuły</p>
              </div>
              <div className="LandingPage__social">
                <button
                  ref={(elem) => this.butt = elem}
                  className="LandingPage__airly"
                  aria-owns={this.state.open ? 'simple-popper' : undefined}
                  aria-haspopup="true"
                  variant="contained"
                  onClick={this.handleClick}
                >
                  <img src={wind} className="LandingPage__airly-image" alt="Otwórz widget Airly" />
                </button>
                <Popover
                  id="simple-popper"
                  open={this.state.open}
                  anchorEl={this.butt}
                  onClose={this.handleClose}
                  className="Airly-modal"
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                  }}
                >
                  <iframe id="airly_1204630261" className="LandingPage__widget" src="https://airly.eu/map/widget.html#lat=52.61479&lng=16.59130&id=6653&w=250&h=330&l=pl&m=true&i=true" style={{width: 250, height: 330, border: "none"}}></iframe>
                </Popover>
                <a href="https://www.facebook.com/vowiestudio/" target="_blank" className="LandingPage__facebook" rel="noopener noreferrer">
                  <img src={facebook} alt="Vowie Studio Facebook" nopin="nopin" className="LandingPage__fb LandingPage__fb--inactive" />
                  <img src={facebookrev} alt="Vowie Studio Facebook" nopin="nopin" className="LandingPage__fb LandingPage__fb--active" />
                </a>
              </div>
            </div>
          </div>
        </div>
        {/* <div className="LandingPage__corona" style={{display: this.state.coronaOpen ? 'block' : 'none'}}>
          <img className="LandingPage__corona-img"src={coronainfo} alt="Informacja dot. zamknięcia biura dla gości z powodu Koronawirusa" nopin="nopin" />
          <button className="LandingPage__close" onClick={() => this.setState({coronaOpen: false})}>
            <img src={close} alt="Zamknij" nopin="nopin"  />
          </button>
        </div> */}
      </div>
    );
  }
}
