import React from 'react';
import ProjectsSubmenu from './ProjectsSubmenu'
import {
  NavLink
} from 'react-router-dom'

import MenuItem from './NavLink'

import logo from '../img/logo.svg'

import '../scss/Menu.css';

const Menu = (props) => (
  <nav className="Menu">
    <ul className="Menu__items">
      <MenuItem to="/" exact liclass="Menu__home"><img src={logo} nopin="nopin" width="50px" height="50px" alt="Stona Główna" /></MenuItem>
      <li className="Menu__item">
        <NavLink to={"/projekty"} activeClassName="active">projekty</NavLink>
        <ProjectsSubmenu types={props.types} location={props.location} />
      </li>
      <MenuItem to="/oferta">oferta</MenuItem>
      <MenuItem to="/o-nas">o nas</MenuItem>
      <MenuItem to="/kontakt">kontakt</MenuItem>
    </ul>
  </nav>
)

export default Menu;
