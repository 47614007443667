import React from 'react'
import { Route } from 'react-router-dom'

// export const url = '/wp/index.php?rest_route=';
export const url = 'https://vowie.com.pl/wp/index.php?rest_route=';

export const RouteWithProps = ({ path, exact, strict, component: Component, location, ...rest }) => (
  <Route
    path={path}
    exact={exact}
    strict={strict}
    location={location}
    render={(props) => <Component {...props} {...rest} />} />
);

export const fetchAllPages = (url) => {
  return fetch(url)
    .then(response => {
      const pages = response.headers.get("X-WP-TotalPages");
      if (pages > 1) {
        const urls = [];
        for (let i = 2; i <= pages; i++) {
          urls.push(`${url}&page=${i}`);
        }
        return Promise.all([response.json()].concat(urls.map(url => fetch(url)
          .then(response => response.json(), reason => {
            console.log(reason);
          })
          .then(json => json)
          .catch(error => console.log(error))
        )));
      } else {
        return response.json()
      }
    }, reason => {
      console.log(reason);
    })
    .then(r => [].concat.apply([], r));
};

export function loadJS(src) {
  const ref = window.document.getElementsByTagName("script")[0];
  const script = window.document.createElement("script");
  script.src = src;
  script.async = true;
  ref.parentNode.insertBefore(script, ref);
}
