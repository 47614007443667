import React from 'react';
import { Link } from 'react-router-dom'
import { RouteWithProps } from '../_shared'

import ShowDetails from './ShowDetails'

import '../scss/Project.css';
import placeholder from '../img/image-placeholder.svg'



class Project extends React.Component {
  constructor(props) {
    super(props);
    this.state = { detailsShown: false };
    this.hideChild = this.hideChild.bind(this);
  }

  hideChild() {
    this.setState({
      detailsShown: false
    });
  }

  render() {
    return (

      <section className="Project" key={this.props.slug}>
        <Link to={`/projekty/${this.props.type}/${this.props.slug}`} className="Project__wrapper">
          <div className="Project__overlay">
            <div className="Project__overlay-content">
              <h3 className="Project__overlay-txt" dangerouslySetInnerHTML={{ __html: this.props.title.rendered }} />
              <h3 className="Project__overlay-txt">{this.props.acf.lokalizacja}</h3>
            </div>
          </div>
          {(this.props._embedded["wp:featuredmedia"])
            ? <img src={this.props._embedded["wp:featuredmedia"]["0"].media_details.sizes.thumbnail.source_url} nopin="nopin" alt="Zdjęcie projektu" />
            : <img src={placeholder} nopin="nopin" alt="placeholder" />}
          <h3 className="Project__mobile-txt" dangerouslySetInnerHTML={{ __html: this.props.title.rendered }} />
        </Link>
        <RouteWithProps exact path={`/projekty/${this.props.type}/${this.props.slug}`} {...this.props} component={ShowDetails} key={Math.random()} />
      </section>

    );
  }
}

export default Project;