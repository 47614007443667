import React from 'react';
import {
    Route, Switch
} from 'react-router-dom'
import { CSSTransitionGroup } from 'react-transition-group'

import MenuItem from './NavLink'

import '../scss/Submenu.css';

const ProjectsSubmenu = (props) => (
    <div className="Submenu">
        <CSSTransitionGroup
            transitionName="subAnim"
            transitionEnterTimeout={500}
            transitionLeaveTimeout={300}
            transitionAppearTimeout={600}
            component="div">
            <Switch location={props.location} key={props.location.pathname.split('/').slice(0, 2).join('/')}>
                <Route path="/(.*)projekt(.*)" render={() =>
                    <div className="Submenu__inner">
                        <ul className="Submenu__items">
                            {props.types.map((v, i) =>
                                <MenuItem to={"/projekty/" + v.slug} liclass="Submenu__item" key={i}>{v.name}</MenuItem>
                            )}
                        </ul>
                    </div>
                } />
            </Switch>
        </CSSTransitionGroup>
    </div>
)

export default ProjectsSubmenu;
