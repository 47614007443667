import React from 'react';
import ReactDOM from 'react-dom';
import { Helmet } from "react-helmet";

import close from '../img/close.svg'

import '../scss/ShowDetails.css';

if (window.NodeList && !NodeList.prototype.forEach) {
  NodeList.prototype.forEach = function (callback, thisArg) {
    thisArg = thisArg || window;
    for (var i = 0; i < this.length; i++) {
      callback.call(thisArg, this[i], i, this);
    }
  };
}

export default class ShowDetails extends React.Component {
  componentDidMount() {
    document.getElementById('root').style.display = 'none';
  }

  componentWillUnmount() {
    document.getElementById('root').style.display = '';
  }

  render() {
    const title = this.props.title.rendered.replace(/&#8211;/g, '-');
    return ReactDOM.createPortal(
      <article className="ShowDetails" id="ShowDetails">
        <Helmet>
          <title>{title} - Vowie Studio</title>
          {(this.props.page && this.props.page.acf)
            ? <meta name="description" content={this.props.page.acf.krotki_opis} />
            : null}
          <meta property="og:type" content="article" />
          <meta property="og:title" content={title + ' - Vowie Studio'} />
          {this.props.acf.krotki_opis ? <meta property="og:description" content={this.props.acf.krotki_opis} /> : null}
          {this.props.acf.gallery.map((v, i) =>
            <meta property="og:image" content={'https://vowie.com.pl' + v.sizes.large} key={i} />
          )}

        </Helmet>
        <section className="ShowDetails__content">
          <h2 className="ShowDetails__title" dangerouslySetInnerHTML={{ __html: this.props.title.rendered }} />
          <h3 className="ShowDetails__date">projekt: {this.props.acf.rok}</h3>
          {(this.props.acf.realizacja)
            ? <h3 className="ShowDetails__date">realizacja: {this.props.acf.realizacja}</h3>
            : ''}
          {(this.props.acf.lokalizacja)
            ? <h3 className="ShowDetails__date">lokalizacja: {this.props.acf.lokalizacja}</h3>
            : ''}
          {(this.props.acf.powierzchnia)
            ? <h3 className="ShowDetails__date">powierzchnia: {this.props.acf.powierzchnia} m<sup>2</sup></h3>
            : ''}
          {(this.props.acf.status)
            ? <h3 className="ShowDetails__date">status: {this.props.acf.status}</h3>
            : ''}
          {(this.props.acf.inwestor)
            ? <h3 className="ShowDetails__date">inwestor: {this.props.acf.inwestor}</h3>
            : ''}

          <span className="ShowDetails__text" dangerouslySetInnerHTML={{ __html: this.props.content.rendered }} />
        </section>
        <div className="ShowDetails__gallery">
          {this.props.acf.gallery.map((v, i) =>
            <img
              src={v.sizes.large}
              alt={v.caption}
              key={i}
              className="ShowDetails__img"
            />)}
        </div>
          <button className="ShowDetails__close" onClick={() => (document.referrer.includes('vowie.com')
          || (document.referrer === '' && this.props.history.length > 2))
              ? this.props.history.goBack()
              : this.props.history.push(this.props.location.pathname.replace(/\/([^\/]*)$/, ''))}
                  >
              zamknij
              <img src={close} alt="Zamknij" nopin="nopin" className="ShowDetails__close-img" />
          </button>
      </article>
      , document.getElementById('details'))
  }
}