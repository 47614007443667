import React from 'react';
import { Helmet } from "react-helmet";
import Project from '../components/Project'
import Spinner from '../components/spinner'

import '../scss/Projekty.css';

export default class Projekty extends React.Component {
  constructor(props) {
    super();
    this.typesId = {};
    props.types.forEach(v => { this.typesId[v.slug] = v.id });
    if (props.posts) {
      this.state = { filtered: this.filterProjects(props.posts, this.getTypeId(props)) };
    } else {
      this.state = { filtered: false };
    }
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.posts) {
      this.setState({
        filtered: this.filterProjects(nextProps.posts, this.getTypeId(nextProps))
      });
    }
  }

  /**
   * Returns selected taxonomy ID based on slug extracted from URL
   * @param props
   * @returns {number}
   */

  getTypeId(props) {
    if (props.location.pathname.match(/\/projekty\/./)) {
      this.type = props.location.pathname
        .replace('/projekty/', '')
        .replace(/\/(.*)/g, '');
    } else {
      this.type = 'wszystkie'
    }
    return this.typesId[this.type]
  }

  /**
   * Filters projects
   * @param projects - projects Array
   * @param typeId - Selected Taxonomy ID
   */

  filterProjects(projects, typeId) {
    return projects.filter(
      el => el.projecttypes.indexOf(typeId) > -1
    );
  }

  render() {
    return (
      <div className="Projekty">
        <Helmet>
          <title>Projekty - Vowie Studio</title>
          {(this.props.page && this.props.page.acf)
            ? <meta name="description" content={this.props.page.acf.description} />
            : null}
        </Helmet>
        {(this.state.filtered)
          ? this.state.filtered.map((v, i) => <Project {...v} type={this.type} key={i} />)
          : <Spinner />}
      </div>
    );
  }
}


